<template>
    <div>
        <div class="card-toolbar mb-5">
            <button v-if="$can('constants.create')" @click="showModal" class="btn btn-primary font-weight-bolder">
                <v-icon>mdi-plus</v-icon>
                {{ $t('constants.new_constant') }}
            </button>
            <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5" >
                <span><i class="la la-filter"></i> <span>{{$t('advanced_filter')}}</span></span>
            </button>
        </div>
        
        <div class="card card-custom mb-5" v-if="showAdvancedSearch">
            <div class="card-body">
                <div class="m-form m-form--fit m--margin-bottom-20">
                    <div class="row">
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="f_name">{{$t('constants.name')}}</label>
                            <input v-model="filters.name" type="text" id="f_name" class="form-control">
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="f_key">{{$t('constants.key')}}</label>
                            <input v-model="filters.key" type="text" id="f_key" class="form-control">
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="f_value">{{$t('constants.value')}}</label>
                            <input v-model="filters.value" type="text" id="f_value" class="form-control">
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="f_main">
                                {{$t('constants.the_main')}}
                            </label>
<!--                            <select name="" id="f_main" v-model="filters.parent_id" type="text" class="form-control">-->
<!--                                <option value="">{{$t('all')}}</option>-->
<!--                                <option v-for="(row, index) in parent_list" :value="row.id" :key="index">{{ row.name }}</option>-->
<!--                            </select>-->
                            <multiselect v-model="parent_filter"
                                         id="f_main"
                                         :placeholder="$t('constants.the_main')"
                                         label="name"
                                         track-by="id"
                                         :options="parent_list"
                                         :multiple="false"
                                         :taggable="false"
                                         :show-labels="false"
                                         :show-no-options="false"
                                         :show-no-results="false"
                                         @search-change="getParentList($event)">
                            </multiselect>
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="f_status">
                                {{$t('status')}}
                            </label>
                            <select name="" id="f_status" v-model="filters.is_active" type="text" class="custom-select">
                                <option value="">{{$t('all')}}</option>
                                <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.text }}</option>
                            </select>
                        </div>
                        <div class="form-group d-inline-flex col-md-6 mt-6 mb-0">
                            <b-button class="mt-auto mr-2" variant="primary" @click="doFilter"><i class="fas fa-search"></i> {{$t('search')}}</b-button>
                            <b-button class="mt-auto mr-2" variant="danger" @click="resetFilter"><i class="fas fa-trash-restore"></i> {{$t('reset_search')}}</b-button>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--begin::User-->
        <div class="card card-custom">
            <div class="card-body">
                <div>
                    <div class="d-flex justify-content-end mb-5">
                        <custom-export-data v-if="$can('constants.export_data')" :data-list="dataList" :file-name="fileName" :fields="json_fields"></custom-export-data>
                    </div>
                </div>

                <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">

                    <template slot="status" slot-scope="props">
                        <b-form-checkbox v-if="$can('constants.change_status')" size="lg" @change="changeStatus(props.row.id, props.row.is_active)" v-model="props.row.is_active" :name="'check-button'+props.row.id" switch :key="props.row.id"></b-form-checkbox>
                        <b-form-checkbox v-else size="lg" :disabled="true" v-model="props.row.is_active" :name="'check-button'+props.row.id" switch :key="props.row.id"></b-form-checkbox>
                    </template>
                    <template slot="actions" slot-scope="props">
                        <!-- <v-icon small color="blue darken-2" v-if="$can('constants.update')" class="mr-2" @click="editItem(props.row)">mdi-pencil</v-icon> -->
                        <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')" v-if="$can('constants.update')" @click="showModal(props.row)">mdi-pencil</v-icon>

                        <v-icon small class="mr-2 text-danger" v-b-tooltip.hover :title="$t('delete')" v-if="$can('constants.delete')" @click="deleteItem(props.row)">mdi-delete</v-icon>
                    </template>
                </v-server-table>


                <!--end: Datatable-->
            </div>
        </div>
        <!--end::User-->

        <b-modal ref="modal" hide-footer :title="$t('constants.constant')">
          <div class="row">
            <div class="col-md-6 mb-5">
              <label for="name">{{$t('constants.name')}}<span class="text-danger">*</span></label>
              <input v-model="item.name" type="text" class="form-control" id="name" :placeholder="$t('constants.name')">
              <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.name[0] }}
                </span>
            </div>
            <div class="col-md-6 mb-5">
              <label for="the_main">{{$t('constants.the_main')}}</label>
              <!--                <select name="" v-model="item.parent_id" class="form-control" id="the_main">-->
              <!--                    <option :value="null"></option>-->
              <!--                    <option v-for="row in parent_list" :value="row.id" :key="row.id">{{ row.name }}</option>-->
              <!--                </select>-->
              <multiselect v-model="parent"
                           id="the_main"
                           :placeholder="$t('constants.the_main')"
                           label="name"
                           track-by="id"
                           :options="parent_list"
                           :multiple="false"
                           :taggable="false"
                           :show-labels="false"
                           :show-no-options="false"
                           :show-no-results="false"
                           @search-change="getParentList($event)">
              </multiselect>
              <span v-if="validation && validation.parent_id" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.parent_id[0] }}
                </span>
            </div>
            <div class="col-md-6 mb-5">
              <label for="key">{{$t('constants.key')}}</label>
              <input v-model="item.key" type="text" class="form-control" id="key" :placeholder="$t('constants.key')" :readonly="item.parent_id">
              <span v-if="validation && validation.key" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.key[0] }}
                </span>
            </div>
            <div class="col-md-6 mb-5">
              <label for="value">{{$t('constants.value')}}<span v-if="item.parent_id" class="text-danger">*</span></label>
              <input v-model="item.value" type="text" class="form-control" id="value" :placeholder="$t('constants.value')">
              <span v-if="validation && validation.value" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.value[0] }}
                </span>
            </div>

            <div class="col-md-6 mb-5">
              <b-form-checkbox size="lg" v-model="item.is_active" name="check-button" switch>{{$t('status')}}</b-form-checkbox>
            </div>

          </div>

            <div class="d-flex justify-content-end">
                <b-button class="mt-2 mr-1" variant="secondary" @click="hideModal">{{$t('close')}}</b-button>
                <b-button class="mt-2 mr-1" variant="primary" @click="save">{{$t('save')}}</b-button>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";

    export default {
        name: "index",
        components: {},
        data() {
            return {
                mainRoute: 'settings/constants',
                subMainRoute: 'settings/constant',
                showAdvancedSearch: false,
                filters: {
                    name: '',
                    key: '',
                    value: '',
                    parent_id: '',
                    is_active: '',
                },
                status_list: [
                    {id: 1, text: this.$t('active')},
                    {id: 0, text: this.$t('inactive')},
                ],
                columns: ['name', 'key', 'status', 'actions'],

                idEditing: null,
                isEditing: false,
                item: {
                    id: null,
                    name: null,
                    parent_id: null,
                    key: null,
                    value: null,
                    is_active: true,
                },
                parent_filter: null,
                parent: null,
                disabledButton: false,
                validation: [],
                parent_list: [],
                dataList : [],
            }
        },
        computed: {
            json_fields: function () {
                let fields = {};
                fields[this.$t('constants.name')] = 'name';
                fields[this.$t('constants.key')] = 'key';
                fields[this.$t('status')] = 'status';
                return fields;
            },
            fileName : function () {
                return this.$t('constants.constants');
            },
            options: function () {
                let that = this;
                return {
                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        limit: that.$t('records'),
                        filterBy: that.$t('Filter') + ' {column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading') + "...",
                    },
                    headings: {
                        name: that.$t('constants.name'),
                        key: that.$t('constants.key'),
                        status: that.$t('status'),
                        actions: that.$t('actions'),

                    },
                    sortable: ['name'],
                    filterByColumn: false,
                    filterable: false,
                    customFilters: [{
                        name: 'alphabet',

                    }],
                    orderBy: {'column': 'created_at'},

                    alwaysShowPerPageSelect: true,
                    perPage: 10,
                    pagination: {chunk: 5, dropdown: false},
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [5, 10, 15, 25],

                    requestFunction(data) {
                        let _params = {
                            ascending: data.ascending,
                            byColumn: data.byColumn,
                            limit: data.limit,
                            orderBy: data.orderBy,
                            page: data.page,
                            filter: data.query,
                            ...that.filters,
                        }
                        return ApiService.query(that.mainRoute, {..._params});

                    },
                    responseAdapter(resp) {
                        that.dataList = resp.data.data.data;
                        return {
                            data: resp.data.data.data,
                            count: resp.data.data.total,
                        }
                    }
                }

            },
        },
        watch: {
            'item.parent_id': function (val) {
                if (val) {
                    let _row = this.parent_list.filter(row => row.id == val);
                    if (_row && _row[0]) {
                        _row = _row[0];
                        this.item.key = _row.key;
                    }
                } else {
                    this.item.key = null;
                }
            },
            parent_filter: function (val) {
                if (val) {
                    this.filters.parent_id = val.id;
                } else {
                    this.filters.parent_id = null;
                }
            },
            parent: function (val) {
                if (val) {
                    this.item.parent_id = val.id;
                } else {
                    this.item.parent_id = null;
                }
            },
        },
        mounted() {
            // let that = this;
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.Constants")}]);
            this.getParentList();
            this.$refs['modal'].hide('hide', function () {
            })
        },
        methods: {
            getFetch() {
                this.$refs.table.refresh();
            },
            openAdvancedSearch() {
                this.showAdvancedSearch = !this.showAdvancedSearch;
            },
            doFilter() {
                this.$refs.table.refresh();
            },
            resetFilter() {
                this.filters.name = '';
                this.filters.key = '';
                this.filters.value = '';
                this.filters.parent_id = '';
                this.filters.is_active = '';
                this.parent_filter = null;

                this.$refs.table.refresh();
            },
            // editItem(item) {
            //     this.idEditing = item.id;
            //     ApiService.get(this.mainRoute + "/" + item.id).then((response) => {
            //         this.item.name = response.data.data.name;
            //         this.item.parent_id = response.data.data.parent_id;
            //         this.item.key = response.data.data.key;
            //         this.item.value = response.data.data.value;
            //         this.item.is_active = response.data.data.is_active;
            //         this.showModal();
            //     }).catch(({errors}) => {
            //         console.error(errors);
            //     })
            // },
            getData(id) {
                ApiService.get(`${this.mainRoute}/${id}`).then((response) => {
                    this.isEditing = true;
                    this.item.id = response.data.data.id;
                    this.item.name = response.data.data.name;
                    this.item.parent_id = response.data.data.parent_id;
                    this.item.key = response.data.data.key;
                    this.item.value = response.data.data.value;
                    this.item.is_active = response.data.data.is_active;
                    this.parent = response.data.data.parent;
                });
            },
            actionDelete(item) {
                ApiService.delete(this.mainRoute + "/" + item.id).then((response) => {
                    this.getFetch();
                    this.$successAlert(response.data.message)
                }).catch((errors) => {
                    this.$errorAlert(errors);
                })
            },
            deleteItem(item) {
                this.$confirmAlert('', this.actionDelete, item);
            },

            showModal(data) {
                if (!data.id) {
                    this.reset();
                    this.$refs['modal'].show()
                } else {
                    this.isEditing = true;
                    this.id = data.id;
                    this.getData(data.id);
                    this.$refs['modal'].show();
                }
            },
            reset() {
                this.item= {
                    id: null,
                    name: null,
                    parent_id: null,
                    key: null,
                    value: null,
                    is_active: true,
                };
                this.validation = [];
                this.isEditing = false;
                this.parent = null;
            },
            hideModal() {
                this.$refs['modal'].hide();
                this.afterSave();
            },
            toggleModal() {
                this.$refs['modal'].toggle('#toggle-btn');
            },
            save() {
                if (this.isEditing) {
                    this.saveEdit();
                } else {
                    this.saveCreate();
                }
            },
            
            saveCreate() {
                // this.item.is_active = this.item.is_active?1:0;
                ApiService.post(this.mainRoute, this.item).then((response) => {
                    this.$successAlert(response.data.message);
                    this.hideModal();
                }).catch((error) => {
                    this.$errorAlert(error);
                    this.validation = error.response ? error.response.data.errors : null;
                });
            },
            saveEdit() {
                // this.item.is_active = this.item.is_active?1:0;
                ApiService.put(`${this.mainRoute}/${this.id}`, this.item).then((response) => {
                    this.$successAlert(response.data.message);
                    this.hideModal();
                }).catch((error) => {
                    this.$errorAlert(error);
                    this.validation = error.response ? error.response.data.errors : null;
                });
            },
            afterSave() {
                this.getFetch();
                this.item = {
                    id: null,
                    name: null,
                    parent_id: null,
                    key: null,
                    value: null,
                    is_active: true,
                };
                this.idEditing = null;
                this.parent = null;
                this.validation = null;

            },
            getParentList() {
                ApiService.get(this.subMainRoute + "/parents").then(response => {
                    this.parent_list = response.data.data;
                });
            },
            changeStatus(id, status) {
                ApiService.patch(this.subMainRoute + '/change-status/' + id, {
                    is_active: (status ? 1 : 0),
                }).then(response => {
                    this.$refs.table.refresh();
                    this.$successAlert(response.data.message);
                }).catch(error => {
                    this.$errorAlert(error);
                });
            },
        },
    };
</script>
